import { PureComponent } from 'react';
import { withTrans } from '@shares/locales/hoc';
import { withFormik, FormikProps } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { AlertType } from '@shares/alert-box';

import {
  SecurityFormValues,
  securitySchema,
  SecurityFormFieldOldPassword,
  SecurityFormFieldNewPassword
} from '@pages/auth/components/security-form-field';
import { authChangePassword } from '@ui/components/context/auth';

import { trackSecurityForm, SecurityFormStatus } from './tracking';
import './style.scss';
export interface UserProfileSecurityProps {
  toggleAlertBox: (errorType: AlertType, massage?: string) => void;
  isError: boolean;
  prefix: string;
}

class UserProfileSecurity extends PureComponent<
  UserProfileSecurityProps & TranslationProps & FormikProps<SecurityFormValues>
> {
  // componentDidUpdate(prevProps: InjectedFormikProps<UserProfileSecurityProps & TranslationProps, SecurityFormValues>) {
  //   if (prevProps.dirty !== this.props.dirty) {
  //     console.log('resetForm');
  //   }
  // }

  render() {
    return (
      <div className="security p-3 p-lg-0">
        <h2>แก้ไขรหัสผ่าน</h2>
        <Form onSubmit={(e: any) => this.props.handleSubmit(e)} className="security-form">
          <Row>
            <Col lg={6}>
              <SecurityFormFieldOldPassword
                errors={this.props.errors}
                values={this.props.values}
                handleChange={(e) => {
                  this.props.setFieldTouched('old_password', true);
                  this.props.handleChange(e);
                }}
                touched={this.props.touched.old_password}
                handleBlur={this.props.handleBlur}
              />
            </Col>
            <Col lg={6}>
              <SecurityFormFieldNewPassword
                errors={this.props.errors}
                values={this.props.values}
                handleChange={(e) => {
                  this.props.setFieldTouched('new_password', true);
                  this.props.handleChange(e);
                }}
                touched={this.props.touched.new_password}
                handleBlur={this.props.handleBlur}
              />
            </Col>
            <Col>
              <Button
                id="account-password-submit"
                type="submit"
                block
                variant={this.props.isError ? 'danger' : 'primary'}
                disabled={
                  this.props.isSubmitting || this.props.isError || Object.keys(this.props.errors).length > 0
                    ? true
                    : false
                }
                className={this.props.isSubmitting ? 'submiting' : ''}
              >
                {this.props.isSubmitting ? (
                  <img src="/assets/shares/loading.svg" style={{ width: 38 }} />
                ) : this.props.isError ? (
                  'โปรดลองใหม่อีกครั้ง'
                ) : (
                  'บันทึก'
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const SecurityForm = withFormik<UserProfileSecurityProps, SecurityFormValues>({
  mapPropsToValues: () => ({
    old_password: '',
    new_password: ''
  }),
  validationSchema: securitySchema(),
  handleSubmit: (values, formikBag) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    authChangePassword(values.old_password, values.new_password)
      .then(() => {
        trackSecurityForm(SecurityFormStatus.Success);
        formikBag.setSubmitting(false);
        formikBag.resetForm({});
        formikBag.props.toggleAlertBox(AlertType.Success);
      })
      .catch((e) => {
        console.log(e.data);
        formikBag.setSubmitting(false);
        trackSecurityForm(SecurityFormStatus.Fail);
        if (e.status == 400) {
          return formikBag.props.toggleAlertBox(AlertType.Error, 'รหัสผ่านปัจจุบันไม่ถูกต้อง โปรดลองใหม่อีกครั้ง');
        }
        formikBag.props.toggleAlertBox(AlertType.Error);
      });
  }
})(withTrans('auth.security')(UserProfileSecurity));

export default SecurityForm;

import { FunctionComponent, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { object, string, InferType } from 'yup';
import Form from 'react-bootstrap/Form';

const textError = 'รหัสผ่านต้องมีอักขระ 8 ตัวขึ้นไป โดยใช้ตัวอักษร หรือตัวเลข';

export const securitySchema = () =>
  object()
    .shape({
      new_password: string()
        .required('กรุณากรอกรหัสผ่านใหม่ของคุณ')
        .min(8, textError)
        .matches(/^(?:(?=.*[a-z])|(?=.*\d)).{8,}$/, textError),
      old_password: string()
        .required('กรุณากรอกรหัสผ่านปัจจุบันของคุณ')
        .min(8, textError)
        .matches(/^(?:(?=.*[a-z])|(?=.*\d)).{8,}$/, textError)
    })
    .defined();

export type SecurityFormValues = InferType<ReturnType<typeof securitySchema>>;

type SecurityFormProps = {
  errors: FormikErrors<SecurityFormValues>;
  values: SecurityFormValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
};

export const SecurityFormFieldOldPassword: FunctionComponent<SecurityFormProps> = (props) => {
  const { errors, values, handleChange, handleBlur, touched } = props;
  const [showOldPassword, setOldShowPassword] = useState<boolean>(false);
  return (
    <>
      <Form.Group className="position-relative">
        <Form.Label>รหัสผ่านปัจจุบัน</Form.Label>
        <Form.Control
          id="account-old-password-input"
          type={showOldPassword ? 'text' : 'password'}
          placeholder="ยืนยันรหัสผ่านปัจจุบัน"
          name="old_password"
          value={values.old_password}
          onChange={handleChange}
          isInvalid={!!errors.old_password && (touched as boolean)}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <i
          className={showOldPassword ? 'icon-eye' : 'icon-eye-off'}
          onClick={() => setOldShowPassword(!showOldPassword)}
        />
        <Form.Control.Feedback type="invalid">{errors.old_password}</Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export const SecurityFormFieldNewPassword: FunctionComponent<SecurityFormProps> = (props) => {
  const { errors, values, handleChange, handleBlur, touched } = props;
  const [showNewPassword, setNewShowPassword] = useState<boolean>(false);
  return (
    <>
      <Form.Group className="position-relative">
        <Form.Label>รหัสผ่านใหม่</Form.Label>
        <Form.Control
          id="account-new-password-input"
          type={showNewPassword ? 'text' : 'password'}
          placeholder="ตั้งรหัสผ่านใหม่"
          name="new_password"
          value={values.new_password}
          onChange={handleChange}
          isInvalid={!!errors.new_password && (touched as boolean)}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <i
          className={showNewPassword ? 'icon-eye' : 'icon-eye-off'}
          onClick={() => setNewShowPassword(!showNewPassword)}
        />
        <Form.Control.Feedback type="invalid">{errors.new_password}</Form.Control.Feedback>
        <Form.Text className={`text-muted ${errors.new_password && touched ? 'd-none' : ''}`}>
          กรุณาตั้งรหัสผ่านที่มีอักขระ 8 ตัวขึ้นไป โดยใช้ตัวอักษร หรือตัวเลข
        </Form.Text>
      </Form.Group>
    </>
  );
};
